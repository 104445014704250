<template>
  <v-container>
    <v-row>
      <v-alert v-if="successMessage" type="success">{{
        successMessage
      }}</v-alert>

      <v-col cols="12">
        <v-toolbar min-width="100%" dark color="primary">
          <v-card-title> SMS Panel Rules</v-card-title>
          <v-spacer> </v-spacer>
          <v-btn @click="openCreateDialog" color="primary"
            >Create Country</v-btn
          >
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(country, index) in countries"
        :key="index"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>{{ country.name }}</v-card-title>

          <v-card-text>
            <v-checkbox
              v-model="country.allow_sms"
              label="Allow SMS"
            ></v-checkbox>
            <v-checkbox
              v-model="country.allow_mail"
              label="Allow Mail"
            ></v-checkbox>
            <v-btn @click="submitCountry(index)">Save</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="createDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>Create Country</v-card-title>
        <v-card-text>
          <v-select
            v-model="newCountry.name"
            :items="countriesData"
            label="Country Name"
            :rules="nameRules"
          ></v-select>

          <v-checkbox
            v-model="newCountry.allow_sms"
            label="Allow SMS"
          ></v-checkbox>
          <v-checkbox
            v-model="newCountry.allow_mail"
            label="Allow Mail"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="createCountry" :disabled="!validateForm">Submit</v-btn>
          <v-btn @click="createDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      countriesData: [],
      createDialog: false,
      successMessage: "",
      countries: [],

      newCountry: {
        name: "",
        allow_sms: false,
        allow_mail: false,
      },
      nameRules: [(v) => !!v || "Country Code is required"],
    };
  },
  mounted() {
    this.$api
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        this.countriesData = response.data
          .map((country) => country.name.common)
          .sort();
      })
      .catch((error) => {
        console.error("Error fetching country data:", error);
      });

    this.fetchCountry();
  },

  computed: {
    validateForm() {
      return !!this.newCountry.name;
    },
  },
  methods: {
    fetchCountry() {
      this.$api
        .get("/country_code/")
        .then((response) => {
          this.countries = response.data.results;
        })
        .catch((error) => {
          console.error("Error fetching country data:", error);
        });
    },
    openCreateDialog() {
      this.createDialog = true;
    },

    createCountry() {
      const isDuplicate = this.countries.some(
        (country) =>
          country.name.toLowerCase() === this.newCountry.name.toLowerCase()
      );

      if (isDuplicate) {
        alert("Country name already exists!", "Duplicate Name");
      } else {
        // this.$api
        //   .get(`/country_code/?name=${this.newCountry.name}`)
        //   .then((response) => {
            this.$api
              .post("/country_code/", this.newCountry)
              .then((response) => {
                this.createDialog = false;
                this.fetchCountry();
              })
          // })
          .catch((error) => {
            console.error("Error checking duplicate country name:", error);
          });
      }
    },
    // createCountry() {
    //   this.$api.post("/country_code/", this.newCountry).then((response) => {
    //     console.log("New country created successfully");
    //     this.createDialog = false;
    //     this.fetchCountry();
    //   });
    // },
    submitCountry(index) {
      const country = this.countries[index];

      const data = {
        id: country.id,
        name: country.name,
        allow_sms: country.allow_sms,
        allow_mail: country.allow_mail,
      };
      this.$api.put(`/country_code/${data.id}/`, data).then((response) => {
        this.successMessage = "You have Changed successfully";
        setTimeout(() => {
          this.successMessage = "";
        }, 2000);
      });
    },
  },
};
</script>
